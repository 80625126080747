import React, { Component } from "react"
import { Section, QuickInfoWrapper } from "../../utils"

export default class ChoppyOrcInfo extends Component {
  render() {
    return (
      <Section style={{ width: "100%" }}>
        <QuickInfoWrapper>
          <p className="text">
            <u>Description:</u>
            <br />
            Simple platformer about an Orc that likes chopping stuff with his
            axe.
            <br />
            <br />
            Created by eddynardo.
            <br />
            Graphics by 0×72 and Adam Saltsman.
            <br />
            Song “The Little Broth” by Rolemusic under a CC by 4.0 attribution
            licence.
            <br />
            <br />
          </p>
          <p className="controllerText">
            <u>Controls:</u>
            <br />
            Keyboard
            <br /> Move: WASD or Arrow keys <br />
            Jump: W or Up Arrow key
            <br /> Use Axe: Spacebar or X or J<br /> Restart: R <br />
            Mute Sound: M <br />
            Menu: Escape
            <br />
            <br /> XBOX controller <br />
            Move: D-pad <br />
            Jump: Button A <br />
            Use Axe: Button X <br />
            Restart: Button Start
            <br /> Mute: Button Y<br /> Menu:Button Back
          </p>
        </QuickInfoWrapper>
      </Section>
    )
  }
}
